import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import "../components/custom.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { Image } from "react-bootstrap";
import logo from "../images/jengaxLogo.svg";

const NavStyles = styled.nav`
  @media (min-width: 320px) and (max-width: 480px) {
    .nav-top {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      flex-flow: column nowrap;
      background: transparent;
      /* position: fixed;  */
      top: 0;
      right: 0;
      /* height: 500px; */
      padding-top: 30px;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    .nav-top {
      width: 86%;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* max-width: 678px; */
      margin: 0 auto;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    .nav-top {
      margin: 0 auto;
      width: 86%;
    }
  }
  @media (min-width: 1025px) {
    .nav-top {
      /* max-width: 80%;
        margin: 0 auto; */
    }
  }
  .nav-top {
    max-width: 1224px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 30px;
  }

  @media (max-width: 320px) {
    .nav-top {
    }
  }

  .menu {
    /* display: flex; */
    /* align-items: center; */

    display: none;
  }
  .showItem {
    display: flex;
    margin-top: 30px;
  }
  @media (min-width: 480px) {
    .menu {
      display: flex;
      align-items: center;
    }
  }

  ul {
    display: flex;
    align-items: center;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    ul {
      display: flex;
      height: 120px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      margin: 0 auto;
    }
  }
  li {
    list-style: none;
    margin-left: 30px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    li {
      /* margin-left: 0; */
      margin: 5px 0;
    }
  }

  a {
    text-decoration: none;
    display: inline-block;
    font-family: "Graphik";
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    padding-bottom: 3px;
    color: #03060b;
    border-bottom: 3px solid transparent;
  }

  .myButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    letter-spacing: 1%;
    font-style: 600;
    font-family: "Graphik";
    color: #ffffff;
    border-width: 0px;
    border-style: unset;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .myButton {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      letter-spacing: 1%;
      font-style: 600;
      font-family: "Graphik";
      color: #ffffff;
      border-width: 0px;
      border-style: unset;
    }
  }
  span {
    padding-left: 8px;
    padding-top: 2px;
  }
  @media (max-width: 480px) {
    .myButton {
      /* margin-top: 20px; */
    }
  }
  .myButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 13px;

    width: 142px;
    height: 33px;

    background: #11253e;
    border-radius: 100px;
  }
  .myButton:hover svg {
    transition: all 0.4s ease 0s;
    transform: translateX(7px);
    position: relative;
  }
  .hamburger {
    display: block;
    margin-top: 12px;
    height: auto;
  }
  .logo-container {
    width: 80%;
    height: 100%;
    display: flex;
    /* align-items: center; */
  }
  @media (min-width: 480px) {
    .hamburger {
      display: none;
      font-size: 30px;
      left: 0;
      right: 0;
    }
  }
  @media (max-width: 480px) {
    .brandlogo {
      /* margin-left: 30px; */
      width: 70%;
      margin: 0 auto;
      text-align: center;
      padding-left: 20px;
    }
  }
  @media (min-width: 1024px) {
    .brandlogo {
      font-size: 48px;
      color: #000000;
      font-family: "BC Kakao";
      font-weight: 900;
      font-style: normal;
      display: flex;
      align-items: center;
      padding-bottom: 0;
      border-bottom: 0;
      margin-top: 10px;
      /* padding-left: 30px; */
    }
  }
  .active {
    border-bottom: 3px solid #03060b;
  }
  .bars {
    height: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-top: 8px;
    margin-right: 15px;
    transition: transform 300ms;
    transform: ${({ showItem }) => (showItem ? "translateX(0)" : "translateX(100%)")};
  }
  .span {
    width: 25px;
    height: 2px;
    background-color: #000;
    transform-origin: 1px;
    border-radius: 5px;
    position: relative;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .logo {
      max-width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 1024px) {
    .logo {
      height: 100%;
      width: 150px;
    }
  }
`;

function Nav() {
  // const [showItem, setShowItem] = React.useState(false)
  const [showItem, setShowItem] = useState(false);
  const toggleItem = () => setShowItem(!showItem);
  return (
    <NavStyles>
      <div className="nav-top">
        <div className="logo-container">
          <Link to="/" className="brandlogo">
            <svg
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 2462 749"
              style={{
                enableBackground: "new 0 0 2462 749",
              }}
              className="logo"
              xmlSpace="preserve"
            >
              <style type="text/css">{"\n    .st0{display:none;fill:#213F85;}\n\t.st1{display:none;}\n\t.st2{display:inline;}\n\t.st3{fill:#3CAF59;}\n\t.st4{opacity:0.6;}\n\t.st5{opacity:0.85;}\n  "}</style>
              <g>
                <path d="M272.89,43.5h98.98v343.97c0,96.87-61.77,150.92-152.33,150.92C127.58,538.39,63,482.94,63,383.96h98.28 c0.7,41.41,19.66,66.69,56.86,66.69c36.5,0,54.76-24.57,54.76-63.18V43.5z" />
                <path d="M579.42,538.39c-100.75,0-173.97-70.09-173.97-178.97c0-109.51,71.34-178.97,173.97-178.97 c100.12,0,170.84,68.21,170.84,171.46c0,11.27-0.63,22.53-2.5,33.79H494.31c4.38,51.31,39.42,80.1,83.23,80.1 c37.55,0,58.2-18.77,69.46-41.92h94.49C722.72,487.7,665.15,538.39,579.42,538.39z M494.94,326.88h164.58 c-1.25-45.68-37.55-74.47-82.6-74.47C534.99,252.41,501.82,279.32,494.94,326.88z" />
                <path d="M1025.6,344.62c0-56.36-30.66-86.76-76.97-86.76c-47.56,0-78.22,30.4-78.22,86.76v193.78h-87.61V187.57h87.61v43.69 c22.53-29.76,60.07-48.76,104.51-48.76c80.72,0,138.3,53.19,138.3,150.08v205.81h-87.61V344.62z" />
                <path d="M1306.06,182.5c54.44,0,93.86,25.03,114.51,55.07v-49.44h88.23v349.18c0,93.87-57.57,168.33-171.46,168.33 c-97.62,0-165.83-48.81-174.59-128.28h86.98c8.76,31.29,41.3,51.94,85.11,51.94c48.18,0,85.73-27.53,85.73-91.99V483.5 c-20.65,30.04-60.07,56.95-114.51,56.95c-88.24,0-158.32-71.96-158.32-180.22S1217.82,182.5,1306.06,182.5z M1328.58,259.47 c-46.93,0-91.36,35.04-91.36,100.75s44.43,103.25,91.36,103.25c48.18,0,91.99-36.29,91.99-102S1376.77,259.47,1328.58,259.47z" />
                <path d="M1699.04,180.45c55.69,0,93.86,26.29,114.51,55.07v-49.44h88.23v346.68h-88.23v-50.69 c-20.65,30.04-60.07,56.32-115.14,56.32c-87.61,0-157.7-71.96-157.7-180.22S1610.81,180.45,1699.04,180.45z M1721.57,257.42 c-46.93,0-91.36,35.04-91.36,100.75s44.43,103.25,91.36,103.25c48.18,0,91.99-36.29,91.99-102S1769.75,257.42,1721.57,257.42z" />
                <g>
                  <polygon points="2399,539.68 2286.43,539.68 2127.81,291.51 2240.38,291.51  " />
                  <polygon points="2399,43.35 2286.43,43.35 2127.81,291.51 2240.38,291.51  " />
                  <polygon points="2159.08,161.04 2102.23,248.02 1969.19,43.35 2081.76,43.35  " />
                  <polygon points="2159.08,421.99 2102.23,335.01 1969.19,539.68 2081.76,539.68  " />
                </g>
              </g>
            </svg>
          </Link>
          <div className="hamburger" onClick={toggleItem}>
            {!showItem ? <FaBars size="30px" /> : <FaTimes size="30px" />}
          </div>
        </div>
        <div className="nav-links">
          <div className={`menu ${showItem ? "showItem" : ""}`}>
            <ul>
              <li>
                <Link to="/" activeClassName="active" exact>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/ideas" activeClassName="active">
                  Ideas
                </Link>
              </li>
              <li>
                <Link to="/venture" activeClassName="active">
                  Ventures
                </Link>
              </li>

              <li>
                <a href="mailto:build@jengax.world" type="button" className="myButton">
                  Get in touch
                  <span>
                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.29289 5.49995L0 2.20706L1.41421 0.792847L6.12132 5.49995L1.41421 10.2071L0 8.79285L3.29289 5.49995Z" fill="white" />
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </NavStyles>
  );
}

export default Nav;
